/* In src/components/Footer.css */
.footer {
  background-color: var(--blue);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-bar {
  display: flex;
  gap: 20px;
}

.footer-link {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}
