.col-image {
  width: 100%;
  max-width: 100%;
}
.col-image-app-view {
  width: 40%;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
}
.headline-1 {
  color: var(--blue);
}
.headline-2 {
  color: var(--blue);
}
.headline-3 {
  color: var(--blue);
}
.special-headline {
  padding-left: 20px !important;
  border-width: 0px;
  border-left-width: 5px;
  border-style: solid;
  border-color: var(--red);
  font-size: 40px;
}
.bgBlue {
  background-color: var(--blue);
}
.section {
  padding: 50px 0px;
}
.content-container {
  max-width: 1140px; /* Die maximale Breite eines Standard-Containers in Bootstrap */
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px; /* Standard-Container-Padding für Bootstrap */
}
.border-seperator {
  border: 2px solid var(--red);
  border-left-width: 0px;
  border-right-width: 0px;
  padding: 25px 0px;
}
.button {
  background-color: var(--blue);
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
}
.button.button-red {
  background-color: var(--red);
}

.button.button-blue {
  background-color: var(--blue);
}

.slider-image-wrapper {
  padding: 0px 15px;
}
.slider-image {
  width: 100%;
  height: auto;
  border: 3px solid #fff;
  border-radius: 15px;
  overflow: hidden;
}

.slick-prev,
.slick-next {
  font-size: 2rem; /* Erhöhe die Schriftgröße, um die Pfeile größer zu machen */
  width: 34px !important;
  height: 31px !important;
  z-index: 1; /* Stelle sicher, dass sie sichtbar sind */
}

.slick-prev {
  left: -34px !important;
}

.slick-next {
  right: -34px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 35px !important; /* Größe des Pfeilsymbols anpassen */
}

.qr-wrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .button {
    width: 100%; /* Buttons füllen die gesamte Breite des Containers */
    text-align: center;
  }

  .section .button-container {
    flex-direction: column; /* Ordnet die Buttons untereinander an */
    gap: 20px; /* Abstand zwischen den Buttons */
  }
}

@media (max-width: 512px) {
  .headline-1 {
    font-size: 30px;
  }

  .headline-2 {
    font-size: 30px;
  }
  .headline-3 {
    font-size: 22px;
  }

  .store-button-wrap {
    text-align: center;
    margin-bottom: 30px;
  }

  .section {
    padding: 25px 0px;
  }

  .section .button-container {
    flex-direction: column; /* Ordnet die Buttons untereinander an */
    gap: 20px; /* Abstand zwischen den Buttons */
  }

  .special-headline {
    margin-left: 15px;
  }
  .reverse-order-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
  .copy {
    margin-bottom: 50px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px !important; /* Größe des Pfeilsymbols anpassen */
  }

  .slick-prev {
    left: -25px !important;
  }

  .slick-next {
    right: -25px !important;
  }
}
