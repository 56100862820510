/* In src/styles/pages.css */
.page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 60px;
}

.page h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.page p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}
