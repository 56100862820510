/* In src/components/ConsentBanner.css */
.consent-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff !important;
  color: #333 !important;
  text-align: center;
  padding: 15px;
  z-index: 1000;
  border-top: 3px solid var(--red) !important;
}

.consent-banner p {
  display: inline;
  margin-bottom: 10px;
}

.consent-text {
  margin-bottom: 10px;
}

.consent-buttons button {
  background-color: var(--blue) !important;
}

.consent-banner button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
}

.consent-banner button:hover {
  background-color: #0056b3;
}
