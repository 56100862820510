/* In src/components/Header.css */
.header-bar {
  background-color: var(--blue);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed; /* Fixiert die Leiste oben */
  top: 0; /* Positioniert sie am oberen Rand */
  left: 0;
  right: 0;
  z-index: 1000; /* Stellt sicher, dass die Leiste über anderen Elementen liegt */
}

.header-logo {
  height: 40px; /* Passe die Höhe des Logos an */
  margin-right: auto;
}

.locale {
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
}

.language-switcher-button-wrapper {
  text-align: center;
}

/* Optional: Falls der Inhalt unterhalb der Header-Leiste beginnt */
.header-spacer {
  height: 60px;
}
